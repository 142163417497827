/* SEARCH-TAG: page-template  */
import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import styled from 'styled-components';
import supportCategoryProps from 'TMPropTypes/supportCategoryProps';
import HeadTags from 'TMComponents/HeadTags';
import Main from 'TMComponents/Layout/Main';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { breakpoints } from 'TMConstants';
import Container from 'TMComponents/Container';
import SupportCategoryList from 'TMComponents/SupportCategoryList';
import Canonical from 'TMComponents/Canonical';
import Hero from 'TMComponents/Hero';
import { BreadcrumbsContext } from 'TMComponents/Breadcrumbs';
import { BannerContext } from 'TMComponents/Banner';
import { LayoutContext } from 'TMComponents/Layout';

import PostList from './_PostList';

const Content = styled(Container)`
    max-width: 1280px;
    padding: 32px 16px;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        ${(props) => props.noImage && 'padding-top: 0'};
    }

    ${(props) => props.noImage && 'padding-top: 0'};
`;

const ContentWrapper = styled(FlexWrapper)`
    flex-wrap: wrap;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        flex-wrap: nowrap;
    }
`;

const SupportPost = ({ data: { supportCategory } }) => {
    const { setIsNavHidden } = useContext(LayoutContext);
    const { setShowBanner } = useContext(BannerContext);
    const { setCrumbs } = useContext(BreadcrumbsContext);

    const isBannerAllowed = true;
    const isCrumbsAllowed = false;

    useEffect(() => {
        setIsNavHidden(false);
        setShowBanner(isBannerAllowed);
        setCrumbs({
            show: isCrumbsAllowed,
            crumbList: [
                { title: 'Help & Support', link: '/help-support/' },
                {
                    title: supportCategory.name,
                    link: `/help-support/${supportCategory.slug}/`
                }
            ]
        });
    }, []);

    const noImage = !supportCategory.headerImageDesktop;

    return (
        <Main
            isBannerAllowed={isBannerAllowed}
            isCrumbsAllowed={isCrumbsAllowed}>
            <HeadTags tags={supportCategory.seoMetaTags.tags} />
            <Canonical href={`help-support/${supportCategory.slug}`} />
            <Hero
                content={supportCategory.heroContent}
                desktopImage={supportCategory.headerImageDesktop}
                mobileImage={supportCategory.headerImageMobile}
                desktopImagePosition={supportCategory.desktopImagePosition}
                mobileImagePosition={supportCategory.mobileImagePosition}
                mobileImageHeight={supportCategory.mobileImageHeight}
                imageFullHeight={supportCategory.imageFullHeight}
                imageCover={supportCategory.imageCover}
                backgroundVariant={supportCategory.backgroundVariant}
            />
            <Content noImage={noImage}>
                <Container>
                    <ContentWrapper align="flex-start">
                        <SupportCategoryList
                            currentCategory={supportCategory.slug}
                        />
                        <PostList
                            categoryName={supportCategory.name}
                            categorySlug={supportCategory.slug}
                            posts={supportCategory.posts}
                        />
                    </ContentWrapper>
                </Container>
            </Content>
        </Main>
    );
};

/**
 * Fetch page data
 */
export const pageQuery = graphql`
    query ($slug: String!) {
        supportCategory: datoCmsSupportCategory(slug: { eq: $slug }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            headerImageDesktop {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            desktopImagePosition
            headerImageMobile {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            mobileImagePosition
            mobileImageHeight
            imageFullHeight
            imageCover
            backgroundVariant
            heroContent {
                ...textStructuredFields
            }
            name
            slug
            posts {
                __typename
                id
                name
                slug
                summary
            }
        }
    }
`;

/**
 * Prop types
 */
SupportPost.propTypes = {
    data: shape({
        supportCategory: shape(supportCategoryProps)
    }).isRequired
};

export default SupportPost;
