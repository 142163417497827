import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import styled from 'styled-components';
import supportPostProps from 'TMPropTypes/supportPostProps';

import { H3 } from 'TMComponents/Typography';
import FlexWrapper from 'TMComponents/FlexWrapper';
import ContentParser from 'TMComponents/ContentParser';
import { Button } from 'TMComponents/Button';

import { breakpoints } from 'TMConstants';

const Wrapper = styled.div`
    width: 100%;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        width: calc(100% - 400px);
    }
`;

const TitleBlock = styled(FlexWrapper)`
    background-color: ${(props) => props.theme.colorSystem.primary[4]};
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.colorSystem.shadows['8']};
    margin-bottom: 24px;
    padding: 16px;
`;

const Title = styled(H3)`
    color: ${({ theme }) => theme.colorSystem.primary[3]};
    margin: 0;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const Post = styled.div`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    box-shadow: ${({ theme }) => theme.colorSystem.shadows['8']};
    margin-bottom: 24px;
    padding: 24px 16px;
`;

const Name = styled(H3)`
    margin: 0;
`;

const Content = styled(ContentParser)`
    margin-left: 0;
    padding: 0;
`;

const PostList = ({ categoryName, categorySlug, posts }) => (
    <Wrapper>
        <TitleBlock>
            <Title>{`${categoryName} (${posts.length})`}</Title>
        </TitleBlock>
        {posts.map(({ id, name, slug, summary }) => (
            <Post key={id}>
                <Name>{name}</Name>
                <Content copy={summary} leftAlign />
                <Button link={`/help-support/${categorySlug}/${slug}/`}>
                    Read More
                </Button>
            </Post>
        ))}
    </Wrapper>
);

PostList.propTypes = {
    categoryName: string.isRequired,
    categorySlug: string.isRequired,
    posts: arrayOf(shape(supportPostProps)).isRequired
};

export default PostList;
