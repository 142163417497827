import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import supportCategoryProps from 'TMPropTypes/supportCategoryProps';

import FlexWrapper from 'TMComponents/FlexWrapper';
import { breakpoints } from 'TMConstants';
import { Body, Bold } from 'TMComponents/Typography';
import ChevronRight from 'TMComponents/Icons/ChevronRight';
import NavLink from 'TMComponents/NavLink';

const Wrapper = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.greys.light};
    padding: 16px 0;
    width: 100%;
`;

const NoMarginText = styled(Body)`
    color: ${({ currentPost, theme }) =>
        currentPost
            ? theme.colorSystem.UIText[1]
            : theme.colorSystem.UIText[2]};

    ${({ currentPost }) => currentPost && Bold}

    margin: 0;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const Header = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;

    &:focus {
        outline: none;
    }
`;

const Chevron = styled(ChevronRight)`
    transform: rotate(90deg);
    transition: transform 0.3s;

    ${(props) => props.open && 'transform: rotate(270deg);'}
`;

const Inner = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.greys.light};
    margin-top: 20px;
    ${(props) => !props.open && 'display: none'};
`;

const QuestionLink = styled(NavLink)`
    display: inline-block;
    margin-top: 16px;
`;

const CategoryPostList = ({
    name: categoryName,
    posts,
    slug: categorySlug,
    currentCategory,
    currentPost
}) => {
    const [open, setOpen] = useState(currentCategory);
    return (
        <Wrapper>
            <Header
                type="button"
                onClick={() => setOpen(!open)}
                data-testid="button"
                aria-label="Toggle category post list">
                <FlexWrapper justify="space-between" align="center">
                    <NavLink href={`/help-support/${categorySlug}/`}>
                        <NoMarginText>{categoryName}</NoMarginText>
                    </NavLink>
                    <Chevron open={open} />
                </FlexWrapper>
            </Header>
            <Inner open={open} data-testid="inner">
                {posts.map(({ id, name, slug }) => (
                    <QuestionLink
                        key={id}
                        href={`/help-support/${categorySlug}/${slug}/`}>
                        <NoMarginText currentPost={currentPost === slug}>
                            {name}
                        </NoMarginText>
                    </QuestionLink>
                ))}
            </Inner>
        </Wrapper>
    );
};

CategoryPostList.propTypes = {
    ...supportCategoryProps,
    currentCategory: bool,
    currentPost: string
};

CategoryPostList.defaultProps = {
    currentCategory: false,
    currentPost: ''
};

export default CategoryPostList;
