import React, { useState } from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import FlexWrapper from 'TMComponents/FlexWrapper';
import { H3 } from 'TMComponents/Typography';
import ChevronRight from 'TMComponents/Icons/ChevronRight';
import { breakpoints } from 'TMConstants';
import CategoryPostList from './CategoryPostList';

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.colorSystem.shadows['4']};
    margin-bottom: 32px;
    padding: 16px;
    width: calc(100% - 32px);

    @media (min-width: ${breakpoints.mobileTablet}px) {
        width: 378px;
        margin-right: 22px;
    }
`;

const Title = styled(H3)`
    margin: 0;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const Header = styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    width: 100%;

    &:focus {
        outline: none;
    }
`;

const Chevron = styled(ChevronRight)`
    transform: rotate(90deg);
    transition: transform 0.3s;

    ${(props) => props.open && 'transform: rotate(270deg);'}

    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: none;
    }
`;

const Inner = styled.div`
    padding-top: 20px;
    ${(props) => !props.open && 'display: none'};

    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: block;
    }
`;

const SupportCategoryList = ({ currentCategory, currentPost }) => {
    const [open, setOpen] = useState(false);
    const { categories } = useStaticQuery(graphql`
        {
            categories: allDatoCmsSupportCategory {
                nodes {
                    __typename
                    id
                    name
                    slug
                    posts {
                        __typename
                        id
                        slug
                        name
                        featured
                    }
                }
            }
        }
    `);
    return (
        <Wrapper>
            <Header
                type="button"
                onClick={() => setOpen(!open)}
                data-testid="header"
                aria-label="Toggle support category list">
                <FlexWrapper justify="space-between" align="center">
                    <Title>Help categories</Title>
                    <Chevron open={open} />
                </FlexWrapper>
            </Header>
            <Inner open={open} data-testid="inner">
                {categories.nodes.map(({ id, ...rest }) => (
                    <CategoryPostList
                        key={id}
                        currentCategory={rest.slug === currentCategory}
                        currentPost={currentPost}
                        {...rest}
                    />
                ))}
            </Inner>
        </Wrapper>
    );
};

SupportCategoryList.propTypes = {
    currentCategory: string,
    currentPost: string
};

SupportCategoryList.defaultProps = {
    currentCategory: '',
    currentPost: ''
};

export default SupportCategoryList;
