import { string, arrayOf, shape } from 'prop-types';
import supportPostProps from './supportPostProps';
import imageProps from './imageProps';

export default {
    id: string,
    headerImageDesktop: shape(imageProps),
    headerImageMobile: shape(imageProps),
    name: string,
    slug: string,
    icon: shape({ icon: shape(imageProps) }),
    posts: arrayOf(shape(supportPostProps))
};
